import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";

export const getProducts = createAsyncThunk(
  "productos/buscar",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(`${url}api/productos/buscar`, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token")
            .replace(/"/g, "")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getProductsTable = createAsyncThunk(
  "productos",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(`${url}api/productos`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token")
            .replace(/"/g, "")}`,
        },
      })
      .then((response) => {
        return { ...response.data, params };
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const asignCodeBar = createAsyncThunk(
  "productos/update/code_bar",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(`${url}api/productos/update/code_bar`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token")
            .replace(/"/g, "")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const bapiPedidos = createAsyncThunk(
  "oc/num_pedido",
  async ({ num_pedido }, thunkAPI) => {
    return await axios
      .get(`${url}api/oc/${num_pedido}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token")
            .replace(/"/g, "")}`,
        },
      })
      .then((response) => {
        response.data.data.folio = num_pedido;
        return response.data;
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

const initialState = {
  /**
   * ? Lista de productos para el autocompletado
   *
   */
  products: [],
  isFetchingProducts: false,
  isErrorProducts: false,
  isSuccessProducts: false,

  /**---------------------- */

  /**
   * ? Paginación de productos
   *
   */

  productsTable: [],
  perPage: 10,
  page: 0,
  count: 0,
  sort: "desc",
  orderBy: "id",

  isFetchingProductsTable: false,
  isErrorProductsTable: false,
  isSuccessProductsTable: false,

  /**----------------------- */

  /**
   * ? Asignar código de barras
   *
   */

  isFetchingAsignCodeBar: false,
  isErrorAsignCodeBar: false,
  isSuccessAsignCodeBar: false,

  /**----------------------- */

  /**
   * ? BAPI Pedidos
   *
   */

  isFetchingBapiPedidos: false,
  isErrorBapiPedidos: false,
  isSuccessBapiPedidos: false,
  pedidoDetalle: {
    costo_pedido: "",
    peso_pedido: "",
    partidas_total: 0,
    status: "",
    volumen_pedido: "",
    productos: [],
    folio: "",
  },

  /**
   * ? Mensajes de error y éxito
   *
   */

  successMessage: null,
  errorMessage: null,
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    clearStateProducts: (state) => {
      state.isFetchingProducts = false;
      state.isErrorProducts = false;
      state.isSuccessProducts = false;
      state.errorMessage = null;
      state.successMessage = null;
      return state;
    },
    clearStateProductsTable: (state) => {
      state.isFetchingProductsTable = false;
      state.isErrorProductsTable = false;
      state.isSuccessProductsTable = false;
      state.errorMessage = null;
      state.successMessage = null;
      return state;
    },
    clearStateAsignCodeBar: (state) => {
      state.isFetchingAsignCodeBar = false;
      state.isErrorAsignCodeBar = false;
      state.isSuccessAsignCodeBar = false;
      state.errorMessage = null;
      state.successMessage = null;
      return state;
    },
    clearStateBapiPedidos: (state) => {
      state.isFetchingBapiPedidos = false;
      state.isErrorBapiPedidos = false;
      state.isSuccessBapiPedidos = false;
      state.errorMessage = null;
      state.successMessage = null;
      return state;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.isFetchingProducts = true;
    },
    [getProducts.fulfilled]: (state, { payload }) => {
      state.isFetchingProducts = false;
      state.isSuccessProducts = true;
      state.successMessage = payload?.message ? payload.message : null;
      state.products = payload.data;
    },
    [getProducts.rejected]: (state, { payload }) => {
      state.isFetchingProducts = false;
      state.isErrorProducts = true;
      state.errorMessage = payload?.message
        ? payload.message
        : "Error de conexión";
    },
    [getProductsTable.pending]: (state) => {
      state.isFetchingProductsTable = true;
    },
    [getProductsTable.fulfilled]: (state, { payload }) => {
      state.isFetchingProductsTable = false;
      state.isSuccessProductsTable = true;

      state.successMessage = payload?.message ? payload.message : null;
      state.productsTable = payload.data.data;
      state.perPage = payload.data.per_page;
      state.page = payload.data.current_page - 1;
      state.count = payload.data.total;

      if (payload?.params) {
        state.sort = payload.params.sort;
        state.orderBy = payload.params.orderBy;
      }
    },
    [getProductsTable.rejected]: (state, { payload }) => {
      state.isFetchingProductsTable = false;
      state.isErrorProductsTable = true;
      state.errorMessage = payload?.message
        ? payload.message
        : "Error de conexión";
    },
    [asignCodeBar.pending]: (state) => {
      state.isFetchingAsignCodeBar = true;
    },
    [asignCodeBar.fulfilled]: (state, { payload }) => {
      state.isFetchingAsignCodeBar = false;
      state.isSuccessAsignCodeBar = true;
      state.successMessage = payload?.message ? payload.message : null;

      let index = state.productsTable.findIndex(
        (product) => product.id === payload.data.id
      );

      if (index === -1) return;
      state.productsTable[index].codigo_barras = payload.data.codigo_barras;
      state.productsTable[index].codigo_barras_empaque =
        payload.data.codigo_barras_empaque;
    },
    [asignCodeBar.rejected]: (state, { payload }) => {
      state.isFetchingAsignCodeBar = false;
      state.isErrorAsignCodeBar = true;
      state.errorMessage = payload?.message
        ? payload.message
        : "Error de conexión";
    },
    [bapiPedidos.pending]: (state) => {
      state.isFetchingBapiPedidos = true;
    },
    [bapiPedidos.fulfilled]: (state, { payload }) => {
      state.isFetchingBapiPedidos = false;
      state.isSuccessBapiPedidos = true;
      state.successMessage = payload?.message ? payload.message : null;
      state.pedidoDetalle = {
        ...payload.data,
        // productos: payload.data.data,
        productos: payload.data.data.map((product) => ({
          ...product,
          MATERIAL: product.MATERIAL.replace(/^0+/, ""),
        })),
      };
    },
    [bapiPedidos.rejected]: (state, { payload }) => {
      state.isFetchingBapiPedidos = false;
      state.isErrorBapiPedidos = true;
      state.errorMessage = payload?.message
        ? payload.message
        : "Error de conexión";
    },
  },
});

export const {
  clearStateProducts,
  clearStateProductsTable,
  clearStateAsignCodeBar,
  reset,
} = productsSlice.actions;

export const selectProducts = (state) => state.products;

export default productsSlice.reducer;
