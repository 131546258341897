import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";

export const getHistorial = createAsyncThunk(
  "api/factura/historial/{id}",
  async ({ id }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/factura/historial/${id}`), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const congelarInvoice = createAsyncThunk(
  "api/factura/congelar/{factura_id}",
  async ({ factura_id }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/factura/congelar`), {
        params: {
          factura_id: factura_id,
        },
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const editCategoria = createAsyncThunk(
  "api/factura/categoria",
  async ({ factura_id, categoria }, thunkAPI) => {
    return await axios
      .put(
        url.concat(`api/factura/categoria`),
        {
          factura_id: factura_id,
          categoria: categoria,
        },
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const cancelarFactura = createAsyncThunk(
  "api/factura/cancelar",
  async ({ factura_id }, thunkAPI) => {
    return await axios
      .put(
        url.concat(`api/factura/cancelar`),
        {
          factura_id: factura_id,
        },
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const setAsRefacturacion = createAsyncThunk(
  "api/factura/refacturar",
  async ({ params }, thunkAPI) => {
    return await axios
      .put(url.concat(`api/factura/refacturar`), params, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const getDetail = createAsyncThunk(
  "api/factura/id",
  async ({ id }, thunkAPI) => {
    try {
      const response = await fetch(url.concat(`api/factura/detalle/${id}`), {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      if (e.response.status === 401) {
        localStorage.setItem("isAuthenticated", "false");
        window.location.reload();
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const uploadAnexos = createAsyncThunk(
  "api/anexo/subir",
  async ({ formData }, thunkAPI) => {
    return await axios
      .post(url.concat("api/anexo/subir"), formData, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

export const getAnexos = createAsyncThunk(
  "api/anexo/obtener/{factura_id}",
  async ({ factura_id, tipo_anexo }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/anexo/obtener`), {
        params: {
          factura_id: factura_id,
          tipo_anexo: tipo_anexo,
        },
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((res) => {
        res.data.tipo_anexo = tipo_anexo;
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        } else {
          return thunkAPI.rejectWithValue(err.response.data);
        }
      });
  }
);

export const updateObservaciones = createAsyncThunk(
  "/api/factura/observaciones/modificar",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat("api/factura/observaciones/modificar"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getCartera = createAsyncThunk(
  "api/cartera/detalle/bapi/factura_id",
  async ({ factura_id }, thunkAPI) => {
    return await axios
      .get(url.concat("api/cartera/bapi"), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
          "Content-Type": "application/json",
        },
        params: {
          factura_id: factura_id,
          detalle: 1,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(err.response.data);
      });
  }
);

const initialState = {
  Factura: {},
  Complementos: [],
  Cotregistros: {},
  DetalleCotizacion: [],
  Receptores: {},
  Historial: {
    transactions: [],
  },
  Anexos: {
    comprobantes: [],
    pagos: [],
  },
  Propietario: {
    id: 0,
    name: "",
    email: "",
    subsidiary_id: 1,
    role_id: 1,
    department_id: 1,
    route_number: null,
  },
  Cartera: [],
  Ligada: null,

  isFetching: false,
  isSuccess: false,
  isError: false,

  isFetchingAnexos: false,
  isSuccessAnexos: false,
  isErrorAnexos: false,

  isFetchingHistorial: false,
  isSuccessHistorial: false,
  isErrorHistorial: false,

  isFetchingUpload: false,
  isSuccessUpload: false,
  isErrorUpload: false,

  isFetchingObservaciones: false,
  isSuccessObservaciones: false,
  isErrorObservaciones: false,

  isFetchingCartera: false,
  isSuccessCartera: false,
  isErrorCartera: false,

  isFetchingCongelar: false,
  isSuccessCongelar: false,
  isErrorCongelar: false,

  isFetchingCategoria: false,
  isSuccessCategoria: false,
  isErrorCategoria: false,

  isFetchingCancelar: false,
  isSuccessCancelar: false,
  isErrorCancelar: false,

  isFetchingRefacturar: false,
  isSuccessRefacturar: false,
  isErrorRefacturar: false,

  isFetchingAll: false,
  isSuccessAll: false,

  errorMessage: "",
  successMessage: "",
  errorCarteraMessage: "",
  errorAnexoMessage: "",
};

export const invoiceDetailSlice = createSlice({
  name: "invoiceDetail",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      return state;
    },
    clearStateUpload: (state) => {
      state.isFetchingUpload = false;
      state.isSuccessUpload = false;
      state.isErrorUpload = false;
      return state;
    },
    clearStateAnexo: (state) => {
      state.isFetchingAnexos = false;
      state.isSuccessAnexos = false;
      state.isErrorAnexos = false;
      //state.Anexos = "";
      state.errorAnexoMessage = "";
      return state;
    },
    clearStateObservaciones: (state) => {
      state.isFetchingObservaciones = false;
      state.isSuccessObservaciones = false;
      state.isErrorObservaciones = false;
      state.errorMessage = "";
      return state;
    },
    clearStateHistorial: (state) => {
      state.isFetchingHistorial = false;
      state.isSuccessHistorial = false;
      state.isErrorHistorial = false;
      state.errorMessage = "";
      state.Historial = {
        transactions: [],
      };
      return state;
    },
    clearStateCartera: (state) => {
      state.Cartera = [];
      state.isFetchingCartera = false;
      state.isSuccessCartera = false;
      state.isErrorCartera = false;
      state.errorMessage = "";
      return state;
    },
    clearStateCongelar: (state) => {
      state.isFetchingCongelar = false;
      state.isSuccessCongelar = false;
      state.isErrorCongelar = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    clearStateCategoria: (state) => {
      state.isFetchingCategoria = false;
      state.isSuccessCategoria = false;
      state.isErrorCategoria = false;
      state.errorMessage = "";
      return state;
    },
    clearStateCancelar: (state) => {
      state.isFetchingCancelar = false;
      state.isSuccessCancelar = false;
      state.isErrorCancelar = false;
      state.errorMessage = "";
      return state;
    },
    clearStateRefacturar: (state) => {
      state.isFetchingRefacturar = false;
      state.isSuccessRefacturar = false;
      state.isErrorRefacturar = false;
      state.errorMessage = "";
      return state;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [getDetail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.Factura = payload.data.Factura;

      state.Complementos = payload.data.Complementos;
      state.Cotregistros = payload.data.Cotregistros;
      state.DetalleCotizacion = payload.data.DetalleCotizacion;
      state.Receptores = payload.data.Receptores;
      state.Propietario = payload.data.Propietario;
      state.Ligada = payload.data.Ligada;
    },
    [getDetail.pending]: (state) => {
      state.isFetching = true;
      state.isFetchingAll = true;
      state.Factura = {};
      state.Complementos = [];
      state.Cotregistros = {};
      state.DetalleCotizacion = [];
      state.Receptores = {};
      state.Historial.transactions = [];
      state.Anexos = {
        comprobantes: [],
        pagos: [],
      };
      state.Propietario = {
        id: 0,
        name: "",
        email: "",
        subsidiary_id: 1,
        role_id: 1,
        department_id: 1,
        route_number: null,
      };
      state.Cartera = [];
    },
    [getDetail.rejected]: (state, { payload }) => {
      state.isFetchingAll = false;
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
      state.Factura = {};
      state.Complementos = [];
      state.Cotregistros = {};
      state.DetalleCotizacion = [];
      state.Receptores = {};
      state.Propietario = {};
      state.Cartera = [];
      state.Historial = {};
      state.Ligada = null;
    },
    [getAnexos.fulfilled]: (state, { payload }) => {
      state.isFetchingAnexos = false;
      state.isSuccessAnexos = true;
      state.isErrorAnexos = false;
      if (payload.tipo_anexo === "comprobantes") {
        state.Anexos.comprobantes = payload.data;
      } else {
        state.Anexos.pagos = payload.data;
      }
      // state.Anexos = payload.data;
      state.errorAnexoMessage = "";
    },
    [getAnexos.pending]: (state) => {
      state.isFetchingAnexos = true;
    },
    [getAnexos.rejected]: (state, { payload }) => {
      state.isFetchingAnexos = false;
      state.isErrorAnexos = true;
      state.errorAnexoMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [uploadAnexos.fulfilled]: (state) => {
      state.isFetchingUpload = false;
      state.isSuccessUpload = true;
    },
    [uploadAnexos.pending]: (state) => {
      state.isFetchingUpload = true;
    },
    [uploadAnexos.rejected]: (state, { payload }) => {
      state.isFetchingUpload = false;
      state.isErrorUpload = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getHistorial.fulfilled]: (state, { payload }) => {
      state.isFetchingHistorial = false;
      state.isSuccessHistorial = true;
      state.Historial = payload.data;
    },
    [getHistorial.pending]: (state) => {
      state.isFetchingHistorial = true;
    },
    [getHistorial.rejected]: (state, { payload }) => {
      state.isFetchingHistorial = false;
      state.isErrorHistorial = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getCartera.fulfilled]: (state, { payload }) => {
      state.isFetchingCartera = false;
      if (Array.isArray(payload.data?.df)) {
        state.isSuccessCartera = true;
        state.Cartera = payload.data.df.map((pago) => {
          return {
            OrgVtas: pago.OrgVtas,
            Cliente: pago.Cliente,
            Factura: pago.Factura,
            Asignacion: pago.Asignacion,
            "No. Doc.": pago["No. Doc."],
            "T.Doc": pago["T.Doc"],
            "Fecha Doc.": pago["Fecha Doc."],
            "Fecha Vencimiento": pago["Fecha Vencimiento"],
            Demora: pago.Demora,
            Credito: pago.Credito,
            Importe: pago.Importe,
            Saldo: pago.Saldo,
            "Fecha Compens.": pago["Fecha Compens."],
            "Doc.comp": pago["Doc.comp"],
            Referencia: pago.Referencia,
            Texto: pago.Texto,
            ZESTADO: pago.ZESTADO,
            "Mon.": pago["Mon."],
          };
        });
      } else {
        state.isErrorCartera = true;
        state.Cartera = [];
        state.errorCarteraMessage = "No se encontraron registros";
      }
    },
    [getCartera.pending]: (state) => {
      state.isFetchingCartera = true;
    },
    [getCartera.rejected]: (state, { payload }) => {
      state.isFetchingCartera = false;
      state.isSuccessCartera = false;
      state.isErrorCartera = true;
      state.errorCarteraMessage =
        typeof payload?.message !== "undefined"
          ? payload?.message
          : "Error al obtener la cartera";
    },
    [updateObservaciones.fulfilled]: (state, { payload }) => {
      state.isFetchingObservaciones = false;
      state.isFetching = false;
      state.isSuccessObservaciones = true;
      if (state.Complementos.length > 0) {
        state.Complementos[0].user_observaciones = payload.data;
      }
    },
    [updateObservaciones.pending]: (state) => {
      state.isFetchingObservaciones = true;
      state.isFetching = true;
    },
    [updateObservaciones.rejected]: (state, { payload }) => {
      state.isFetchingObservaciones = false;
      state.isFetching = false;
      state.isErrorObservaciones = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [congelarInvoice.fulfilled]: (state, { payload }) => {
      state.Factura.congelado = payload.data.congelado;
      state.Factura.last_movement = payload.data.movement_time;
      state.isSuccessCongelar = true;
      state.isFetchingCongelar = false;
      state.isErrorCongelar = false;
      state.isFetching = false;
      state.successMessage = payload.message;
    },
    [congelarInvoice.pending]: (state) => {
      state.isFetchingCongelar = true;
    },
    [congelarInvoice.rejected]: (state, { payload }) => {
      state.isFetchingCongelar = false;
      state.isSuccessCongelar = false;
      state.isErrorCongelar = true;
      state.errorMessage =
        typeof payload.message !== "undefined"
          ? payload.message
          : "Error al congelar la factura";
    },
    [editCategoria.fulfilled]: (state, { payload }) => {
      state.isSuccessCategoria = true;
      state.isFetchingCategoria = false;
      state.isErrorCategoria = false;
      state.Factura.categoria = payload.data.new_category;
      state.Factura.last_movement = payload.data.movement_time;
      state.Factura.status = payload.data.new_status;
      //state.isFetching = false;
    },
    [editCategoria.pending]: (state) => {
      state.isFetchingCategoria = true;
      //state.isFetching = true;
    },
    [editCategoria.rejected]: (state, { payload }) => {
      state.isFetchingCategoria = false;
      state.isSuccessCategoria = false;
      state.isErrorCategoria = true;
      state.errorMessage =
        typeof payload.message !== "undefined"
          ? payload.message
          : "Error al actualizar la categoría";
      //state.isFetching = false;
    },

    [cancelarFactura.fulfilled]: (state, { payload }) => {
      state.isSuccessCancelar = true;
      state.isFetchingCancelar = false;
      state.isErrorCancelar = false;
      state.Factura.status_id = 7;
      state.Factura.last_movement = payload.data.movement_time;
    },
    [cancelarFactura.pending]: (state) => {
      state.isFetchingCancelar = true;
    },
    [cancelarFactura.rejected]: (state, { payload }) => {
      state.isFetchingCancelar = false;
      state.isSuccessCancelar = false;
      state.isErrorCancelar = true;
      state.errorMessage =
        typeof payload.message !== "undefined"
          ? payload.message
          : "Error al cancelar la factura";
    },
    [setAsRefacturacion.fulfilled]: (state, { payload }) => {
      state.isSuccessRefacturar = true;
      state.isFetchingRefacturar = false;
      state.isErrorRefacturar = false;
      state.Factura.status_id = 17;
      state.Factura.last_movement = payload.data.movement_time;
    },
    [setAsRefacturacion.pending]: (state) => {
      state.isFetchingRefacturar = true;
    },
    [setAsRefacturacion.rejected]: (state, { payload }) => {
      state.isFetchingRefacturar = false;
      state.isSuccessRefacturar = false;
      state.isErrorRefacturar = true;
      state.errorMessage =
        typeof payload?.message !== "undefined"
          ? payload.message
          : "Error al marcar como refacturación";
    },
  },
});

export const {
  clearState,
  clearStateAnexo,
  clearStateUpload,
  clearStateObservaciones,
  clearStateHistorial,
  clearStateCartera,
  clearStateCongelar,
  clearStateCategoria,
  clearStateCancelar,
  clearStateRefacturar,
  reset,
} = invoiceDetailSlice.actions;

export const selectInvoiceDetail = (state) => state.invoiceDetail;

export const selectAnexoInvoice = (state) => state.invoiceDetail.Anexos;

export default invoiceDetailSlice.reducer;
