import { reset as ResetUser } from "../features/userSlice";
import { reset as ResetInvoice } from "../features/invoiceSlice";
import { reset as ResetPortales } from "../features/portalesSlice";
import { reset as ResetTransaction } from "../features/transactionSlice";
import { reset as ResetDetail } from "../features/invoiceDetailSlice";
import { reset as ResetRoute } from "../features/routeSlice";
import { reset as ResetAdmin } from "../features/adminSlice";
import { reset as ResetCollection } from "../features/collectionSlice";
import { reset as ResetProducts } from "../features/productsSlice";
import { reset as ResetOrder } from "../features/ordenesCompraSlice";

export const ResetStore = (dispatch) => {
  dispatch(ResetUser());
  dispatch(ResetInvoice());
  dispatch(ResetPortales());
  dispatch(ResetTransaction());
  dispatch(ResetDetail());
  dispatch(ResetRoute());
  dispatch(ResetAdmin());
  dispatch(ResetCollection());
  dispatch(ResetProducts());
  dispatch(ResetOrder());
};
