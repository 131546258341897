import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import userReducer from "../features/userSlice";
import invoiceReducer from "../features/invoiceSlice";
import invoiceDetailReducer from "../features/invoiceDetailSlice";
import portalesReducer from "../features/portalesSlice";
import transactionReducer from "../features/transactionSlice";
import routeReducer from "../features/routeSlice";
import adminReducer from "../features/adminSlice";
import productsReducer from "../features/productsSlice";
import accountReducer from "../features/accountSlice";
import collectionReducer from "../features/collectionSlice";
import formReducer from "../features/formSlice";
import ordenesCompraReducer from "../features/ordenesCompraSlice";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

const reducers = combineReducers({
  user: userReducer,
  invoice: invoiceReducer,
  invoiceDetail: invoiceDetailReducer,
  portales: portalesReducer,
  transaction: transactionReducer,
  route: routeReducer,
  admin: adminReducer,
  account: accountReducer,
  form: formReducer,
  products: productsReducer,
  collection: collectionReducer,
  ordenesCompra: ordenesCompraReducer,
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  //blacklist: ['admin']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
});
