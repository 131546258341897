import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";
import { asignarSecuencia } from "../helpers/AsingSequency";
import { formatFactura } from "../helpers/FormatFactura";

export const getRoutes = createAsyncThunk(
  "/api/rutas",
  async (params, thunkAPI) => {
    return await axios
      .get(url.concat("api/rutas"), {
        params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getRouteInfo = createAsyncThunk(
  "/api/rutas/general/info/ruta_id",
  async ({ ruta_id }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/rutas/general/info/${ruta_id}`), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getRouteDetail = createAsyncThunk(
  "/api/rutas/detalle/ruta_id",
  async ({ ruta_id }, thunkAPI) => {
    return await axios
      .get(url.concat(`api/rutas/detalle/${ruta_id}`), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getRouteDetailV2 = createAsyncThunk(
  "/api/rutas/detalle/v2/ruta_id",
  async (id, thunkAPI) => {
    return await axios
      .get(url.concat(`api/rutas/detalle/v2/${id}`), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getResponsiva = createAsyncThunk(
  "/api/rutas/responsiva/formato",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/rutas/responsiva/formato"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch(async (e) => {
        let responseObj = await e.response.data.text();
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(responseObj);
      });
  }
);

export const getPicking = createAsyncThunk(
  "/api/picking/formato",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/picking/formato"), {
        params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: "blob",
      })
      .then((response) => {
        //return response.data;
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch(async (e) => {
        let responseObj = await e.response.data.text();
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(responseObj);
      });
  }
);

export const getFormatoCarga = createAsyncThunk(
  "/api/carga/carga",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/picking/carga"), {
        params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: params.formato === "pdf" ? "blob" : "json",
      })
      .then((response) => {
        if (params.formato === "pdf") {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else {
          return response.data;
        }
      })
      .catch(async (e) => {
        let responseObj = await e.response.data.text();
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(responseObj);
      });
  }
);

export const getReportePicking = createAsyncThunk(
  "/api/picking/reporte",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/picking/reporte"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "reporte_picking" + Date.now() + ".xlsx");
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        response.data = [];
      })
      .catch(async (e) => {
        let responseObj = await e.response.data.text();
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(responseObj);
      });
  }
);

export const createScheduleRoute = createAsyncThunk(
  "/api/rutas/post",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/rutas/post"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getRouteEmails = createAsyncThunk(
  "/api/correos/ruta/factura",
  async ({ subsidiary_id }, thunkAPI) => {
    return await axios
      .get(url.concat("api/correos/ruta/factura"), {
        params: {
          subsidiary_id,
        },
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const addRouteEmail = createAsyncThunk(
  "/api/correos/ruta/factura/add",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/correos/ruta/factura"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const editRouteEmail = createAsyncThunk(
  "/api/correos/ruta/factura/edit",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat("api/correos/ruta/factura"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const deleteRouteEmail = createAsyncThunk(
  "/api/correos/ruta/factura/delete",
  async ({ data }, thunkAPI) => {
    return await axios
      .delete(url.concat("api/correos/ruta/factura/" + data.id), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        // if (e.response.status === 401) {
        //   localStorage.setItem("isAuthenticated", "false");
        //   window.location.reload();
        // }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const sendEmail = createAsyncThunk(
  "/api/rutas/mail/relacion",
  async (data, thunkAPI) => {
    return await axios
      .post(url.concat("api/rutas/mail/relacion"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const updateRuta = createAsyncThunk(
  "/api/rutas/put",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat("api/rutas/post"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const deleteRuta = createAsyncThunk(
  "/api/rutas/eliminar/ruta_id",
  async ({ ruta_id }, thunkAPI) => {
    return await axios
      .delete(url.concat(`api/rutas/eliminar/${ruta_id}`), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        response.data.ruta = ruta_id;
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

/**
 * ? Buscar factura por QR en la ruta
 * @param {Object} params
 *
 * @returns {Object} data
 */

export const buscarFacturaRoute = createAsyncThunk(
  "/api/factura/detalleqr/busqueda/route",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/factura/detalleqr"), {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        params: {
          ...params,
          // route: 1,
        },
      })
      .then((response) => {
        response.data.reception = params?.route === 0 ? 1 : 0;
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getCamiones = createAsyncThunk(
  "/api/camiones/crud/get",
  async (params, thunkAPI) => {
    return await axios
      .get(url.concat("api/camiones"), {
        params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const postCamiones = createAsyncThunk(
  "/api/camiones/crud/post",
  async ({ data }, thunkAPI) => {
    return await axios
      .post(url.concat("api/camiones/post"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const putCamiones = createAsyncThunk(
  "/api/camiones/crud/put",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat("api/camiones/put"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const desactivateCamiones = createAsyncThunk(
  "/api/camiones/desactivar",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat("api/camiones/desactivar"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getEmbarqueReport = createAsyncThunk(
  "/api/rutas/reporte_embarque",
  async ({ params }, thunkAPI) => {
    return await axios
      .get(url.concat("api/rutas/reporte_embarque"), {
        params,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          "reporte_embarque" + params.ruta_id + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        response.data = [];
      })
      .catch(async (e) => {
        let responseObj = await e.response.data.text();
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(responseObj);
      });
  }
);

export const addObservation = createAsyncThunk(
  "/api/rutas/observaciones/modificar",
  async ({ data }, thunkAPI) => {
    return await axios
      .put(url.concat("api/factura/observaciones/modificar"), data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
        },
      })
      .then((response) => {
        return {
          ...response.data,
          factura_id: data.id,
        };
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

const initialState = {
  // name: "",
  // date: "",
  routes: {
    data: [],
    page: 1,
    perPage: 50,
    total: 0,
  },
  // activeRoutes: {
  //   data: [],
  // },
  // deliveredRoutes: {
  //   data: [],
  // },
  // scheduleRoutes: {},
  // routeDetail: {
  //   route: 0,
  //   facturas: [],
  //   facturasEntrega: [],
  // },
  /**
   *
   * routeSliceV2
   *
   */

  routeDetailV2: {
    route: {
      id: 0,
      truck: {
        id: 0,
        name: "",
      },
      driver: {
        id: 0,
        name: "",
      },
      fecha_programada: "",
      fecha_entrada: "",
      fecha_salida: "",
      status: "",
    },
    facturas: [],
    new_facturas: [],
  },

  trucks: [],

  routeEmails: [],

  routeInfo: [],
  // responsiva: "",

  isFetchingRoutes: false,
  isSuccessRoutes: false,
  isErrorRoutes: false,

  // isSuccessActivas: false,
  // isErrorActivas: false,
  // isFetchingDelivered: false,
  // isSuccessDelivered: false,
  // isErrorDelivered: false,

  isFetchingDetalle: false,
  isSuccessDetalle: false,
  isErrorDetalle: false,

  // isFetchingSchedule: false,
  // isSuccessSchedule: false,
  // isErrorSchedule: false,

  isFetchingCreate: false,
  isSuccessCreate: false,
  isErrorCreate: false,

  // isFetchingInfo: false,
  // isSuccessInfo: false,
  // isErrorInfo: false,

  isFetchingMail: false,
  isSuccessMail: false,
  isErrorMail: false,

  isFetchingResponsiva: false,
  isSuccessResponsiva: false,
  isErrorResponsiva: false,

  isFetchingPicking: false,
  isSuccessPicking: false,
  isErrorPicking: false,

  isFetchingUpdate: false,
  isSuccessUpdate: false,
  isErrorUpdate: false,

  isFetchingDelete: false,
  isSuccessDelete: false,
  isErrorDelete: false,

  isFetchingFacturaRoute: false,
  isSuccessFacturaRoute: false,
  isErrorFacturaRoute: false,

  isFetchingEmails: false,
  isSuccessEmails: false,
  isErrorEmails: false,

  isFetchingAddEmail: false,
  isSuccessAddEmail: false,
  isErrorAddEmail: false,

  isFetchingEditEmail: false,
  isSuccessEditEmail: false,
  isErrorEditEmail: false,

  isFetchingDeleteEmail: false,
  isSuccessDeleteEmail: false,
  isErrorDeleteEmail: false,

  isFetchingCamiones: false,
  isSuccessCamiones: false,
  isErrorCamiones: false,

  isFetchingPostCamiones: false,
  isSuccessPostCamiones: false,
  isErrorPostCamiones: false,

  isFetchingPutCamiones: false,
  isSuccessPutCamiones: false,
  isErrorPutCamiones: false,

  isFetchingDesactivateCamiones: false,
  isSuccessDesactivateCamiones: false,
  isErrorDesactivateCamiones: false,

  isSuccessReportePicking: false,
  isErrorReportePicking: false,
  isFetchingReportePicking: false,

  isSuccessEmbarqueReport: false,
  isErrorEmbarqueReport: false,
  isFetchingEmbarqueReport: false,

  isSuccessObservaciones: false,
  isErrorObservaciones: false,
  isFetchingObservaciones: false,

  /**
   * Reporte de carga v2
   *
   */

  isSuccessCarga: false,
  isErrorCarga: false,
  isFetchingCarga: false,
  carga_ruta: [],

  successMessage: "",
  errorMessage: "",
  errorData: [],
};

export const routeSlice = createSlice({
  name: "route",
  initialState: initialState,
  reducers: {
    clearStateSchedule: (state) => {
      state.isFetchingSchedule = false;
      state.isSuccessSchedule = false;
      state.isErrorSchedule = false;
      return state;
    },
    clearStateCreate: (state) => {
      state.isFetchingCreate = false;
      state.isSuccessCreate = false;
      state.isErrorCreate = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    clearStateDetalle: (state) => {
      state.isFetchingDetalle = false;
      state.isSuccessDetalle = false;
      state.isErrorDetalle = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    // clearStateInfo: (state) => {
    //   state.isFetchingInfo = false;
    //   state.isSuccessInfo = false;
    //   state.isErrorInfo = false;
    //   return state;
    // },
    clearStateMail: (state) => {
      state.isFetchingMail = false;
      state.isSuccessMail = false;
      state.isErrorMail = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    clearStateResponsiva: (state) => {
      state.isFetchingResponsiva = false;
      state.isSuccessResponsiva = false;
      state.isErrorResponsiva = false;
      state.errorMessage = "";
      return state;
    },
    reset: () => initialState,
    clearUpdate: (state) => {
      state.isFetchingUpdate = false;
      state.isSuccessUpdate = false;
      state.isErrorUpdate = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    clearFormatos: (state) => {
      state.isFetchingPicking = false;
      state.isSuccessPicking = false;
      state.isErrorPicking = false;
      state.isFetchingCarga = false;
      state.isSuccessCarga = false;
      state.isErrorCarga = false;
      state.isFetchingResponsiva = false;
      state.isErrorResponsiva = false;
      state.isSuccessResponsiva = false;
      state.errorMessage = "";
      state.errorData = [];
      return state;
    },
    // clearStateDelivered: (state) => {
    //   state.isFetchingDelivered = false;
    //   state.isSuccessDelivered = false;
    //   state.isErrorDelivered = false;
    //   state.errorMessage = "";
    //   return state;
    // },
    // clearDetalle: (state) => {
    //   state.routeDetail = {
    //     route: 0,
    //     facturas: [],
    //     facturasEntrega: [],
    //   };
    //   return state;
    // },
    // clearInfo: (state) => {
    //   state.routeInfo = [];
    //   return state;
    // },
    updateCategoriaALM: (state, { payload }) => {
      let facturasEntrega = state.routeDetailV2.new_facturas;

      const index = facturasEntrega.findIndex(
        (factura) => factura.id === payload.id
      );

      if (index >= 0) {
        facturasEntrega[index]["categoria_alm"] = payload.categoria;
        if (payload.categoria === "Reenvio") {
          facturasEntrega[index]["observaciones"] = payload.observaciones;

          if (payload?.entregas && payload?.entregas.length > 0) {
            let entregasArray = payload.entregas;
            let productos_factura = facturasEntrega[index]["productos"].map(
              (producto) => {
                for (let c = 0; c < entregasArray.length; c++) {
                  if (
                    producto.noidentificacion ===
                    entregasArray[c].noidentificacion
                  ) {
                    producto.entregado = entregasArray[c].entregado;
                    producto.volumen_entregado_ruta =
                      entregasArray[c].volumen_entregado;
                  }
                }
                return producto;
              }
            );

            facturasEntrega[index]["productos"] = productos_factura;
            state.routeDetailV2.new_facturas = facturasEntrega;

            return state;
          }
        }

        let productos_factura = facturasEntrega[index]["productos"].map(
          (producto) => {
            return {
              ...producto,
              entregado:
                payload.categoria !== "Sin recibir" &&
                payload.categoria !== "Devolucion" &&
                payload.categoria !== "Reenvio"
                  ? 1
                  : 0,
            };
          }
        );

        facturasEntrega[index]["productos"] = productos_factura;

        state.routeDetailV2.new_facturas = facturasEntrega;
      }
      return state;
    },
    clearStateFacturaRoute: (state) => {
      state.isFetchingFacturaRoute = false;
      state.isSuccessFacturaRoute = false;
      state.isErrorFacturaRoute = false;
      return state;
    },

    clearStateRoutes: (state) => {
      state.isFetchingRoutes = false;
      state.isSuccessRoutes = false;
      state.isErrorRoutes = false;
      state.errorMessage = "";
      state.successMessage = "";
    },

    /**
     * ? CRUD EMAILS
     */

    clearStateEmails: (state) => {
      state.isFetchingEmails = false;
      state.isSuccessEmails = false;
      state.isErrorEmails = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    clearStateAddEmail: (state) => {
      state.isFetchingAddEmail = false;
      state.isSuccessAddEmail = false;
      state.isErrorAddEmail = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    clearStateEditEmail: (state) => {
      state.isFetchingEditEmail = false;
      state.isSuccessEditEmail = false;
      state.isErrorEditEmail = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    clearStateDeleteEmail: (state) => {
      state.isFetchingDeleteEmail = false;
      state.isSuccessDeleteEmail = false;
      state.isErrorDeleteEmail = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    clearStateDelete: (state) => {
      state.isFetchingDelete = false;
      state.isSuccessDelete = false;
      state.isErrorDelete = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },

    /**
     * ? CRUD Camiones
     */
    clearStateCamiones: (state) => {
      state.isFetchingCamiones = false;
      state.isSuccessCamiones = false;
      state.isErrorCamiones = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },

    clearStatePostCamiones: (state) => {
      state.isFetchingPostCamiones = false;
      state.isSuccessPostCamiones = false;
      state.isErrorPostCamiones = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },

    clearStatePutCamiones: (state) => {
      state.isFetchingPutCamiones = false;
      state.isSuccessPutCamiones = false;
      state.isErrorPutCamiones = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    clearStateDesactivateCamiones: (state) => {
      state.isFetchingDesactivateCamiones = false;
      state.isSuccessDesactivateCamiones = false;
      state.isErrorDesactivateCamiones = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    clearStateReportePicking: (state) => {
      state.isFetchingReportePicking = false;
      state.isSuccessReportePicking = false;
      state.isErrorReportePicking = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    clearStateEmbarqueReport: (state) => {
      state.isFetchingEmbarqueReport = false;
      state.isSuccessEmbarqueReport = false;
      state.isErrorEmbarqueReport = false;

      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    deleteRouteFactura: (state, { payload }) => {
      const index = state.routeDetailV2.new_facturas.findIndex(
        (factura) => factura.id === payload
      );
      if (index >= 0) {
        let new_facturas = state.routeDetailV2.new_facturas;
        new_facturas.splice(index, 1);
        state.routeDetailV2.new_facturas = asignarSecuencia(new_facturas);
      }
    },
    discardChanges: (state) => {
      state.routeDetailV2.new_facturas = state.routeDetailV2.facturas;
    },
    clearNewFacturas: (state) => {
      state.routeDetailV2.new_facturas = [];
    },
    /**
     * ?Reducer para actualizar el estatus de la ruta cuando sale o entra de reparto.
     * @param {*} state
     *
     */
    updateRouteInfo: (state, { payload }) => {
      let updatedRoute = {
        ...state.routeDetailV2.route,
        step:
          payload.step === 2
            ? "Ruta programada"
            : payload.step === 3
            ? "En reparto"
            : payload.step === 4
            ? "Entregada"
            : "Sin estatus",
        fecha_salida: payload.fecha_salida,
        fecha_entrada: payload?.fecha_entrada ? payload.fecha_entrada : null,
      };
      state.routeDetailV2.route = updatedRoute;
      return state;
    },

    clearStateObservaciones: (state) => {
      state.isFetchingObservaciones = false;
      state.isSuccessObservaciones = false;
      state.isErrorObservaciones = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    updateInvoicesAlmBDCategory: (state) => {
      let invoices = state.routeDetailV2.new_facturas.map((factura) => {
        factura.categoria_alm_bd = factura.categoria_alm;
        return factura;
      });

      state.routeDetailV2.new_facturas = invoices;
      return state;
    },
  },
  extraReducers: {
    [getRoutes.fulfilled]: (state, { payload }) => {
      state.isFetchingRoutes = false;
      state.isSuccessRoutes = true;

      state.routes.total = payload.data.total;
      state.routes.page = payload.data.current_page - 1;
      state.routes.perPage = payload.data.per_page;

      const tableRoutes = payload.data.data.map((route) => {
        return {
          ID: route.id,
          Ruta: route.name,
          Sucursal: route.subsidiary,
          "Fecha programada": route.fecha_programada
            ? route.fecha_programada
            : "Sin fecha programada",
          "Fecha de salida": route.fecha_salida
            ? route.fecha_salida
            : "Sin fecha de salida",
          "Fecha de entrada": route.fecha_entrada
            ? route.fecha_entrada
            : "Sin fecha de entrada",
          Repartidor: route.driver?.name
            ? route.driver.name
            : "Sin repartidor asignado",
          "Vehiculo asignado": route.truck?.name
            ? route.truck.name
            : "Sin vehiculo asignado",
          Estatus:
            route.step === 2
              ? "Ruta programada"
              : route.step === 3
              ? "En reparto"
              : route.step === 4
              ? "Recibida"
              : "Sin estatus",
        };
      });
      state.routes.data = tableRoutes;
    },
    [getRoutes.pending]: (state) => {
      state.isFetchingRoutes = true;
    },
    [getRoutes.rejected]: (state, { payload }) => {
      state.isFetchingRoutes = false;
      state.isErrorRoutes = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getRouteDetail.fulfilled]: (state, { payload }) => {
      state.isFetchingDetalle = false;
      state.isSuccessDetalle = true;
      state.routeDetail.route = payload.data.route;
      state.routeDetail.facturas = payload.data.facturas;
      state.routeDetail.facturasEntrega = [];
    },
    [getRouteDetail.pending]: (state) => {
      state.isFetchingDetalle = true;
    },
    [getRouteDetail.rejected]: (state, { payload }) => {
      state.isFetchingDetalle = false;
      state.isErrorDetalle = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },

    [getRouteDetailV2.fulfilled]: (state, { payload }) => {
      state.isFetchingDetalle = false;
      state.isSuccessDetalle = true;

      state.routeDetailV2.route = {
        id: payload.data.route.id,
        name: payload.data.route.name,
        fecha_programada: payload.data.route.fecha_programada,
        fecha_salida: payload.data.route.fecha_salida,
        fecha_entrada: payload.data.route.fecha_entrada,
        step:
          payload.data.route.step === 2
            ? "Ruta programada"
            : payload.data.route.step === 3
            ? "En reparto"
            : payload.data.route.step === 4
            ? "Entregada"
            : "Sin estatus",
        driver: payload.data.route.driver
          ? payload.data.route.driver
          : "Sin repartidor asignado",
        truck: payload.data.route.truck
          ? payload.data.route.truck
          : "Sin vehiculo asignado",
      };

      if (payload.data.facturas.length > 0) {
        let formattedFacturas = payload.data.facturas.map((factura) =>
          formatFactura(factura)
        );
        state.routeDetailV2.facturas = formattedFacturas;
        state.routeDetailV2.new_facturas = formattedFacturas;
      } else {
        state.routeDetailV2.facturas = [];
        state.routeDetailV2.new_facturas = [];
      }
      state.successMessage = payload.message;
      // state.routeDetail.facturasEntrega = [];
    },
    [getRouteDetailV2.pending]: (state) => {
      state.isFetchingDetalle = true;
    },
    [getRouteDetailV2.rejected]: (state, { payload }) => {
      state.isFetchingDetalle = false;
      state.isErrorDetalle = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getResponsiva.fulfilled]: (state, { payload }) => {
      state.isFetchingResponsiva = false;
      state.isSuccessResponsiva = true;
    },
    [getResponsiva.pending]: (state) => {
      state.isFetchingResponsiva = true;
    },
    [getResponsiva.rejected]: (state, { payload }) => {
      let error_message = JSON.parse(payload);
      state.isFetchingResponsiva = false;
      state.isErrorResponsiva = true;

      /**
       * Guarda los productos que no se encuentran en la base de productos en el estado errorData
       *
       */

      state.errorMessage = error_message?.message
        ? error_message.message
        : "Error en el servidor al generar la responsiva, favor de contactar al administrador";

      if (error_message?.data && error_message?.data.length > 0) {
        state.errorData = error_message.data;
      }

      // state.errorMessage = error_message?.data
      //   ? error_message?.message + ": " + JSON.stringify(error_message.data)
      //   : error_message?.message
      //   ? error_message.message
      //   : "Error en el servidor responsiva";
    },
    [getPicking.fulfilled]: (state, { payload }) => {
      state.isFetchingPicking = false;
      state.isSuccessPicking = true;
    },
    [getPicking.pending]: (state) => {
      state.isFetchingPicking = true;
    },
    [getPicking.rejected]: (state, { payload }) => {
      let error_message = JSON.parse(payload);
      state.isFetchingPicking = false;
      state.isErrorPicking = true;

      /**
       * Guarda los productos que no se encuentran en la base de productos en el estado errorData
       *
       */

      state.errorMessage = error_message?.message
        ? error_message.message
        : "Error en el servidor al generar el Picking, favor de contactar al administrador";

      if (error_message?.data && error_message?.data.length > 0) {
        state.errorData = error_message.data;
      }

      // state.errorMessage = error_message?.data
      //   ? error_message?.message + ": " + JSON.stringify(error_message.data)
      //   : error_message?.message
      //   ? error_message.message
      //   : "Error en el servidor responsiva";
    },
    [getFormatoCarga.fulfilled]: (state, { payload }) => {
      state.isFetchingCarga = false;
      state.isSuccessCarga = true;
      if (payload?.data) {
        state.carga_ruta = payload.data;
      } else {
        state.carga_ruta = [];
      }
    },
    [getFormatoCarga.pending]: (state) => {
      state.isFetchingCarga = true;
    },
    [getFormatoCarga.rejected]: (state, { payload }) => {
      let error_message = JSON.parse(payload);
      state.isFetchingCarga = false;
      state.isErrorCarga = true;

      state.errorMessage = error_message?.message
        ? error_message.message
        : "Error en el servidor al generar el formato de carga, favor de contactar al administrador";

      if (error_message?.data && error_message?.data.length > 0) {
        state.errorData = error_message.data;
      }

      // state.errorMessage = error_message?.data
      //   ? error_message?.message + ": " + JSON.stringify(error_message.data)
      //   : error_message?.message
      //   ? error_message.message
      //   : "Error en el servidor responsiva";
    },
    [getReportePicking.fulfilled]: (state) => {
      state.isFetchingReportePicking = false;
      state.isSuccessReportePicking = true;
      state.successMessage = "Reporte de picking generado correctamente";
    },
    [getReportePicking.pending]: (state) => {
      state.isFetchingReportePicking = true;
    },
    [getReportePicking.rejected]: (state, { payload }) => {
      let error_message = JSON.parse(payload);
      state.isFetchingReportePicking = false;
      state.isErrorReportePicking = true;

      state.errorMessage = error_message?.message
        ? error_message.message
        : "Error en el servidor al generar el reporte de picking, favor de contactar al administrador";

      if (error_message?.data && error_message?.data.length > 0) {
        state.errorData = error_message.data;
      }

      // state.errorMessage = error_message?.data
      //   ? error_message?.message + ": " + JSON.stringify(error_message.data)
      //   : error_message?.message
      //   ? error_message.message
      //   : "Error en el servidor responsiva";
    },
    [getRouteInfo.fulfilled]: (state, { payload }) => {
      state.isFetchingInfo = false;
      state.isSuccessInfo = true;
      state.routeInfo = payload.data;
    },
    [getRouteInfo.pending]: (state) => {
      state.isFetchingInfo = true;
    },
    [getRouteInfo.rejected]: (state, { payload }) => {
      state.isFetchingInfo = false;
      state.isErrorInfo = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [createScheduleRoute.fulfilled]: (state, { payload }) => {
      state.isFetchingCreate = false;
      state.isSuccessCreate = true;
      state.successMessage = payload.message;
      let routes = state.routes.data;
      let new_route = {
        ID: payload.data.id,
        Ruta: payload.data.name,
        Sucursal: payload.data?.subsidiary?.name
          ? payload.data.subsidiary.name
          : "Sin sucursal",
        "Fecha programada": payload.data.fecha_programada
          ? payload.data.fecha_programada
          : "Sin fecha programada",
        "Fecha de salida": payload.data.fecha_salida
          ? payload.data.fecha_salida
          : "Sin fecha de salida",
        "Fecha de entrada": payload.data.fecha_entrada
          ? payload.data.fecha_entrada
          : "Sin fecha de entrada",
        Repartidor: payload.data.driver?.name
          ? payload.data.driver.name
          : "Sin repartidor asignado",
        "Vehiculo asignado": payload.data.truck?.name
          ? payload.data.truck.name
          : "Sin vehiculo asignado",
        Estatus:
          payload.data.step === 2
            ? "Ruta programada"
            : payload.data.step === 3
            ? "En reparto"
            : payload.data.step === 4
            ? "Recibida"
            : "Sin estatus",
      };
      routes.unshift(new_route);
      state.routes.data = routes;
      state.routes.total++;
    },
    [createScheduleRoute.pending]: (state) => {
      state.isFetchingCreate = true;
    },
    [createScheduleRoute.rejected]: (state, { payload }) => {
      state.isFetchingCreate = false;
      state.isErrorCreate = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [sendEmail.fulfilled]: (state, { payload }) => {
      state.isFetchingMail = false;
      state.isSuccessMail = true;
      state.successMessage = payload.message;
      // state.routes = payload.data;
    },
    [sendEmail.pending]: (state) => {
      state.isFetchingMail = true;
    },
    [sendEmail.rejected]: (state, { payload }) => {
      state.isFetchingMail = false;
      state.isErrorMail = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [getRouteEmails.fulfilled]: (state, { payload }) => {
      state.isFetchingEmails = false;
      state.isSuccessEmails = true;
      state.routeEmails = payload.data;
      state.successMessage = payload.message;
    },
    [getRouteEmails.pending]: (state) => {
      state.isFetchingEmails = true;
    },
    [getRouteEmails.rejected]: (state, { payload }) => {
      state.isFetchingEmails = false;
      state.isErrorEmails = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [addRouteEmail.fulfilled]: (state, { payload }) => {
      state.isFetchingAddEmail = false;
      state.isSuccessAddEmail = true;
      state.routeEmails = [...state.routeEmails, payload.data];
      state.successMessage = payload.message;
    },
    [addRouteEmail.pending]: (state) => {
      state.isFetchingAddEmail = true;
    },
    [addRouteEmail.rejected]: (state, { payload }) => {
      state.isFetchingAddEmail = false;
      state.isErrorAddEmail = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [editRouteEmail.fulfilled]: (state, { payload }) => {
      state.isFetchingEditEmail = false;
      state.isSuccessEditEmail = true;
      let emails = state.routeEmails;
      let index = emails.findIndex((email) => email.id === payload.data.id);
      emails[index] = payload.data;
      state.routeEmails = emails;
      state.successMessage = payload.message;
    },
    [editRouteEmail.pending]: (state) => {
      state.isFetchingEditEmail = true;
    },
    [editRouteEmail.rejected]: (state, { payload }) => {
      state.isFetchingEditEmail = false;
      state.isErrorEditEmail = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [deleteRouteEmail.fulfilled]: (state, { payload }) => {
      state.isFetchingDeleteEmail = false;
      state.isSuccessDeleteEmail = true;
      let emails = state.routeEmails;
      let index = emails.findIndex((email) => email.id === payload.data.id);
      emails.splice(index, 1);
      state.routeEmails = emails;
      state.successMessage = payload.message;
    },
    [deleteRouteEmail.pending]: (state) => {
      state.isFetchingDeleteEmail = true;
    },
    [deleteRouteEmail.rejected]: (state, { payload }) => {
      state.isFetchingDeleteEmail = false;
      state.isErrorDeleteEmail = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [updateRuta.fulfilled]: (state, { payload }) => {
      state.isFetchingUpdate = false;
      state.isSuccessUpdate = true;
      state.successMessage = payload.message;
      state.routeDetailV2.route = {
        id: payload.data.id,
        name: payload.data.name,
        fecha_programada: payload.data.fecha_programada,
        fecha_salida: payload.data.fecha_salida,
        fecha_entrada: payload.data.fecha_entrada,
        step:
          payload.data.step === 2
            ? "Ruta programada"
            : payload.data.step === 3
            ? "En reparto"
            : payload.data.step === 4
            ? "Entregada"
            : "Sin estatus",
        driver: payload.data.driver
          ? payload.data.driver
          : "Sin repartidor asignado",
        truck: payload.data.truck
          ? payload.data.truck
          : "Sin vehiculo asignado",
      };
      state.routeDetailV2.facturas = state.routeDetailV2.new_facturas;
    },
    [updateRuta.pending]: (state) => {
      state.isFetchingUpdate = true;
    },
    [updateRuta.rejected]: (state, { payload }) => {
      state.isFetchingUpdate = false;
      state.isErrorUpdate = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [deleteRuta.fulfilled]: (state, { payload }) => {
      state.isFetchingDelete = false;
      state.isSuccessDelete = true;
      state.successMessage = payload.message;
      let routes = state.routes.data;

      for (let i = 0; i < routes.length; i++) {
        if (routes[i]["ID"] === payload.ruta) {
          routes.splice(i, 1);
          break;
        }
      }
      state.routes.data = routes;
      state.routes.total--;
    },
    [deleteRuta.pending]: (state) => {
      state.isFetchingDelete = true;
    },
    [deleteRuta.rejected]: (state, { payload }) => {
      state.isFetchingDelete = false;
      state.isErrorDelete = true;
      state.errorMessage = payload?.message
        ? payload?.data
          ? payload.message + ". " + JSON.stringify(payload.data)
          : payload.message
        : "Error en el servidor";
    },
    [buscarFacturaRoute.fulfilled]: (state, { payload }) => {
      state.isFetchingFacturaRoute = false;
      state.isSuccessFacturaRoute = true;
      state.successMessage = payload.message;

      let facturas_route = state.routeDetailV2.new_facturas;

      const index = facturas_route.findIndex(
        (fac) => fac.id === payload.data.id
      );

      if (index >= 0) {
        if (payload?.reception === 1) {
          facturas_route.splice(index, 1);
          facturas_route.unshift(formatFactura(payload.data));
          state.routeDetailV2.new_facturas = facturas_route;
          return;
        }
        state.isErrorFacturaRoute = true;
        state.errorMessage =
          "Esta factura ya esta cargada en la ruta. Folio Factura: " +
          facturas_route[index].folio;
      } else {
        if (payload?.reception === 0) {
          facturas_route.unshift(formatFactura(payload.data));
          let formattedFacturas = asignarSecuencia(facturas_route);
          state.routeDetailV2.new_facturas = formattedFacturas;
        } else {
          state.isErrorFacturaRoute = true;
          state.errorMessage =
            "Esta factura no esta cargada en la ruta. Folio Factura: " +
            payload.data.folio;
        }
      }
    },
    [buscarFacturaRoute.pending]: (state) => {
      state.isFetchingFacturaRoute = true;
    },
    [buscarFacturaRoute.rejected]: (state, { payload }) => {
      state.isFetchingFacturaRoute = false;
      state.isErrorFacturaRoute = true;
      let error_message = {
        message: "",
        error: null,
      };

      if (payload?.data?.message && payload?.data?.error) {
        error_message = {
          message: payload.data.message,
          error: payload.data.error !== [] ? payload.data.error : null,
        };
      } else {
        error_message = {
          message: payload?.message
            ? payload.message
            : "Error de conexión con el servidor, verifique su conexión a internet",
        };
      }

      state.errorMessage = error_message?.error
        ? error_message.message + ". " + error_message.error
        : error_message.message;
    },
    [getCamiones.fulfilled]: (state, { payload }) => {
      state.isFetchingCamiones = false;
      state.isSuccessCamiones = true;
      state.successMessage = payload.message;

      if (payload.data.length > 0) {
        let camiones = payload.data.map((camion) => {
          return {
            ...camion,
            subsidiary: camion.subsidiary.name,
          };
        });
        state.trucks = camiones;
      } else {
        state.trucks = [];
      }
    },
    [getCamiones.pending]: (state) => {
      state.isFetchingCamiones = true;
    },
    [getCamiones.rejected]: (state, { payload }) => {
      state.isFetchingCamiones = false;
      state.isErrorCamiones = true;
      state.errorMessage = payload?.message
        ? payload.message
        : "Error en el servidor";
    },
    [postCamiones.fulfilled]: (state, { payload }) => {
      state.isFetchingPostCamiones = false;
      state.isSuccessPostCamiones = true;
      state.successMessage = payload.message;
      let new_truck = {
        ...payload.data,
        subsidiary: payload.data.subsidiary.name,
      };
      state.trucks.push(new_truck);
    },
    [postCamiones.pending]: (state) => {
      state.isFetchingPostCamiones = true;
    },
    [postCamiones.rejected]: (state, { payload }) => {
      state.isFetchingPostCamiones = false;
      state.isErrorPostCamiones = true;
      state.errorMessage = payload?.message
        ? payload.message
        : "Error en el servidor";
    },
    [putCamiones.fulfilled]: (state, { payload }) => {
      state.isFetchingPutCamiones = false;
      state.isSuccessPutCamiones = true;
      state.successMessage = payload.message;
      let trucks = state.trucks;
      const index = trucks.findIndex((truck) => truck.id === payload.data.id);
      trucks[index] = {
        ...payload.data,
        subsidiary: payload.data.subsidiary.name,
      };
      state.trucks = trucks;
    },
    [putCamiones.pending]: (state) => {
      state.isFetchingPutCamiones = true;
    },
    [putCamiones.rejected]: (state, { payload }) => {
      state.isFetchingPutCamiones = false;
      state.isErrorPutCamiones = true;
      state.errorMessage = payload?.message
        ? payload.message
        : "Error en el servidor";
    },
    [desactivateCamiones.fulfilled]: (state, { payload }) => {
      state.isFetchingDesactivateCamiones = false;
      state.isSuccessDesactivateCamiones = true;
      state.successMessage = payload.message;
      let trucks = state.trucks;
      trucks = trucks.filter((truck) => truck.id !== payload.data.id);
      state.trucks = trucks;
    },
    [desactivateCamiones.pending]: (state) => {
      state.isFetchingDesactivateCamiones = true;
    },
    [desactivateCamiones.rejected]: (state, { payload }) => {
      state.isFetchingDesactivateCamiones = false;
      state.isErrorDesactivateCamiones = true;
      state.errorMessage = payload?.message
        ? payload.message
        : "Error en el servidor";
    },
    [getReportePicking.fulfilled]: (state) => {
      state.isFetchingEmbarqueReport = false;
      state.isSuccessEmbarqueReport = true;
      state.successMessage = "Reporte de embarque generado correctamente";
    },
    [getReportePicking.pending]: (state) => {
      state.isFetchingEmbarqueReport = true;
    },
    [getReportePicking.rejected]: (state, { payload }) => {
      state.isFetchingEmbarqueReport = false;
      state.isErrorEmbarqueReport = true;
      state.errorMessage = payload?.message
        ? payload.message
        : "Error en el servidor";
    },
    [addObservation.fulfilled]: (state, { payload }) => {
      state.isFetchingObservaciones = false;
      state.isSuccessObservaciones = true;
      state.successMessage = payload.message;

      let new_facturas = state.routeDetailV2.new_facturas;
      const index = new_facturas.findIndex(
        (factura) => factura.id === payload.factura_id
      );
      new_facturas[index] = {
        ...new_facturas[index],
        user_observaciones: payload.data,
      };

      state.new_facturas = new_facturas;
    },
    [addObservation.pending]: (state) => {
      state.isFetchingObservaciones = true;
    },
    [addObservation.rejected]: (state, { payload }) => {
      state.isFetchingObservaciones = false;
      state.isErrorObservaciones = true;
      state.errorMessage = payload?.message
        ? payload.message
        : "Error en el servidor";
    },
  },
});

export const {
  clearStateCreate,
  clearStateSchedule,
  clearStateDetalle,
  clearStateInfo,
  clearStateMail,
  clearStateResponsiva,
  clearStateFacturaRoute,
  clearUpdate,
  clearDelete,
  clearFormatos,
  clearDetalle,
  clearInfo,
  clearStateDelivered,
  clearStateEmails,
  clearStateAddEmail,
  clearStateEditEmail,
  clearStateDeleteEmail,
  clearStateCamiones,
  clearStatePostCamiones,
  clearStatePutCamiones,
  clearStateDesactivateCamiones,
  clearStateReportePicking,
  clearStateEmbarqueReport,
  clearStateRoutes,
  clearStateDelete,
  clearNewFacturas,
  // initializeRoute,
  // loadSchRoute,
  // updateRoute,
  clearStateObservaciones,
  reset,
  updateCategoriaALM,
  updateInvoicesAlmBDCategory,
  deleteRouteFactura,
  discardChanges,
  updateRouteInfo,
} = routeSlice.actions;

export const selectRoute = (state) => state.route;

export default routeSlice.reducer;
