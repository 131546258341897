import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import url from "../url";

export const getOrdenesCompra = createAsyncThunk(
  "ordenes_compra",
  async (_, thunkAPI) => {
    return await axios
      .get(`${url}api/ordenes_compra`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token")
            .replace(/"/g, "")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getOrdenCompraByPedido = createAsyncThunk(
  "ordenes_compra/num_pedido",
  async ({ num_pedido }, thunkAPI) => {
    return await axios
      .get(`${url}api/ordenes_compra/${num_pedido}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token")
            .replace(/"/g, "")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const getScannedProductOrdenCompra = createAsyncThunk(
  "ordenes_compra/scan/producto",
  async ({ codigo_barras }, thunkAPI) => {
    return await axios
      .get(`${url}api/ordenes_compra/scan/producto`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token")
            .replace(/"/g, "")}`,
        },
        params: {
          num_pedido:
            thunkAPI.getState().ordenesCompra.ordenCompraDetalle.oc.num_pedido,
          codigo_barras,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const receiveOC = createAsyncThunk(
  "ordenes_compra/receive",
  async ({ num_pedido, productos, tipoRecepcion }, thunkAPI) => {
    return await axios
      .post(
        `${url}api/ordenes_compra/receive`,
        {
          num_pedido,
          productos,
          tipoRecepcion,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token")
              .replace(/"/g, "")}`,
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

export const cancelOC = createAsyncThunk(
  "ordenes_compra/cancel",
  async ({ num_pedido }, thunkAPI) => {
    return await axios
      .put(`${url}api/ordenes_compra/cancel/${num_pedido}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token")
            .replace(/"/g, "")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.setItem("isAuthenticated", "false");
          window.location.reload();
        }
        return thunkAPI.rejectWithValue(e.response.data);
      });
  }
);

const initialState = {
  ordenesCompra: {
    data: [],
    perPage: 10,
    page: 0,
    total: 0,
  },

  ordenCompraDetalle: {
    oc: {},
    productos: [],
    screenMode: "view",
    scannedProduct: {
      codigo_sap: "",
      codigo_barras: "",
    },
  },

  isFetchingOrdenesCompra: false,
  isSuccessOrdenesCompra: false,
  isErrorOrdenesCompra: false,

  isFetchingOrdenCompraDetalle: false,
  isSuccessOrdenCompraDetalle: false,
  isErrorOrdenCompraDetalle: false,

  isFetchingScannedProduct: false,
  isSuccessScannedProduct: false,
  isErrorScannedProduct: false,
  openProductDialog: false,

  isFetchingRecieveOC: false,
  isSuccessRecieveOC: false,
  isErrorRecieveOC: false,

  isFetchingCancelOC: false,
  isSuccessCancelOC: false,
  isErrorCancelOC: false,

  message: "",
  error: {
    code: "",
    message: "",
  },
};

/**
 * Creates a Redux slice for managing state related to orders.
 * @typedef {Object} initialState
 * @property {boolean} isFetchingOrdenesCompra - Indicates if orders are being fetched.
 * @property {boolean} isSuccessOrdenesCompra - Indicates if orders were fetched successfully.
 * @property {boolean} isErrorOrdenesCompra - Indicates if there was an error fetching orders.
 * @property {boolean} isFetchingOrdenCompraDetalle - Indicates if order details are being fetched.
 * @property {boolean} isSuccessOrdenCompraDetalle - Indicates if order details were fetched successfully.
 * @property {boolean} isErrorOrdenCompraDetalle - Indicates if there was an error fetching order details.
 * @property {string} message - A message related to the state.
 * @property {Object} error - An object containing error information.
 * @property {string} error.code - An error code.
 * @property {string} error.message - An error message.
 * @property {Object} ordenesCompra - An object containing order information.
 * @property {Array} ordenesCompra.data - An array of order data.
 * @property {number} ordenesCompra.perPage - The number of orders per page.
 * @property {number} ordenesCompra.page - The current page of orders.
 * @property {number} ordenesCompra.total - The total number of orders.
 * @property {Object} ordenCompraDetalle - An object containing order details.
 * @property {Object} ordenCompraDetalle.oc - An object containing order information.
 * @property {Array} ordenCompraDetalle.productos - An array of order products.
 * @property {string} ordenCompraDetalle.screenMode - The current screen mode.
 * @property {boolean} openProductDialog - Indicates if the product dialog is open.
 * @property {Object} scannedProduct - An object containing scanned product information.
 * @property {boolean} isFetchingScannedProduct - Indicates if a scanned product is being fetched.
 * @property {boolean} isSuccessScannedProduct - Indicates if a scanned product was fetched successfully.
 * @property {boolean} isErrorScannedProduct - Indicates if there was an error fetching a scanned product.
 * @property {boolean} isFetchingRecieveOC - Indicates if an order is being received.
 * @property {boolean} isSuccessRecieveOC - Indicates if an order was received successfully.
 * @property {boolean} isErrorRecieveOC - Indicates if there was an error receiving an order.
 * @type {import("@reduxjs/toolkit").Slice}
 */
const ordenesCompraSlice = createSlice({
  name: "ordenes_compra",
  initialState,
  reducers: {
    clearStateOrdenesCompra: (state) => {
      state.isFetchingOrdenesCompra = false;
      state.isSuccessOrdenesCompra = false;
      state.isErrorOrdenesCompra = false;
    },
    clearStateOrdenCompraDetalle: (state) => {
      state.isFetchingOrdenCompraDetalle = false;
      state.isSuccessOrdenCompraDetalle = false;
      state.isErrorOrdenCompraDetalle = false;
    },
    clearStateScannedProduct: (state) => {
      state.isFetchingScannedProduct = false;
      state.isSuccessScannedProduct = false;
      state.isErrorScannedProduct = false;
    },
    clearStateRecieveOC: (state) => {
      state.isFetchingRecieveOC = false;
      state.isSuccessRecieveOC = false;
      state.isErrorRecieveOC = false;
    },
    clearStateError: (state) => {
      state.message = "";
      state.error = {
        code: "",
        message: "",
      };
    },
    changeScreenMode: (state, { payload }) => {
      state.ordenCompraDetalle.screenMode = payload;
      state.ordenCompraDetalle.oc.status =
        payload === "reception"
          ? "En recepción"
          : state.ordenCompraDetalle.oc.status;
    },
    closeProductDialog: (state) => {
      state.openProductDialog = false;
    },
    /**
     * payload: { codigo_sap, cantidad_recibida }
     * @param {*} state
     * @param {*} param1
     */
    changeCantidadRecibida: (state, { payload }) => {
      const index = state.ordenCompraDetalle.productos.findIndex(
        (producto) => producto.codigo === payload.codigo
      );
      if (index !== -1) {
        if (
          payload.cantidad_recibida >
          state.ordenCompraDetalle.productos[index].cantidad
        )
          return;
        let producto = {
          ...state.ordenCompraDetalle.productos[index],
          cantidad_recibida: +payload.cantidad_recibida,
        };
        state.scannedProduct = producto;
        state.ordenCompraDetalle.productos[index] = producto;
      }
    },
    clearStateCancelOC: (state) => {
      state.isFetchingCancelOC = false;
      state.isSuccessCancelOC = false;
      state.isErrorCancelOC = false;
      state.message = "";
    },
    reset: () => initialState,
  },
  extraReducers: {
    [getOrdenesCompra.pending]: (state) => {
      state.isFetchingOrdenesCompra = true;
      state.isSuccessOrdenesCompra = false;
      state.isErrorOrdenesCompra = false;
    },
    [getOrdenesCompra.fulfilled]: (state, { payload }) => {
      state.isFetchingOrdenesCompra = false;
      state.isSuccessOrdenesCompra = true;
      state.isErrorOrdenesCompra = false;
      state.message = payload.message;
      state.ordenesCompra.data = payload.data.data;
      state.ordenesCompra.perPage = payload.data.per_page;
      state.ordenesCompra.page = payload.data.current_page;
      state.ordenesCompra.total = payload.data.total;
    },
    [getOrdenesCompra.rejected]: (state, { payload }) => {
      state.isFetchingOrdenesCompra = false;
      state.isSuccessOrdenesCompra = false;
      state.isErrorOrdenesCompra = true;
      state.message = payload.message;
      state.error = payload.error;
    },
    [getOrdenCompraByPedido.pending]: (state) => {
      state.isFetchingOrdenCompraDetalle = true;
      state.isSuccessOrdenCompraDetalle = false;
      state.isErrorOrdenCompraDetalle = false;
    },
    [getOrdenCompraByPedido.fulfilled]: (state, { payload }) => {
      state.isFetchingOrdenCompraDetalle = false;
      state.isSuccessOrdenCompraDetalle = true;
      state.isErrorOrdenCompraDetalle = false;
      state.message = payload.message;
      state.ordenCompraDetalle.oc = payload.data.oc;
      state.ordenCompraDetalle.productos = payload.data.productos;
      state.ordenCompraDetalle.screenMode = "view";
    },
    [getOrdenCompraByPedido.rejected]: (state, { payload }) => {
      state.isFetchingOrdenCompraDetalle = false;
      state.isSuccessOrdenCompraDetalle = false;
      state.isErrorOrdenCompraDetalle = true;
      state.message = payload?.message ?? "Error al obtener la orden de compra";
      state.error = payload?.error ?? {
        code: "",
        message: "",
      };

      if (payload?.error?.code === "OC_WAITING_CORRECTION") {
        state.ordenCompraDetalle.oc = payload.data.oc;
        state.ordenCompraDetalle.productos = payload.data.productos;
        state.ordenCompraDetalle.screenMode = "view";
      }
    },
    [getScannedProductOrdenCompra.pending]: (state) => {
      state.isFetchingScannedProduct = true;
      state.isSuccessScannedProduct = false;
      state.isErrorScannedProduct = false;
    },
    [getScannedProductOrdenCompra.fulfilled]: (state, { payload }) => {
      state.isFetchingScannedProduct = false;
      state.isSuccessScannedProduct = true;
      state.isErrorScannedProduct = false;
      state.message = payload.message;

      // buscar el producto en el arreglo de productos de la orden de compra
      // si existe, actualizar la cantidad
      // si no existe, no hacer nada

      const index = state.ordenCompraDetalle.productos.findIndex(
        (producto) => producto.codigo === payload.data.codigo
      );
      if (index !== -1) {
        let producto = {
          ...state.ordenCompraDetalle.productos[index],
          codigo_barras: payload.data.codigo_barras,
        };
        // state.ordenCompraDetalle.productos[index].pivot.cantidad_recibida += 1;
        state.openProductDialog = true;
        if (producto.cantidad === producto.cantidad_recibida) return;
        producto.cantidad_recibida += 1;
        state.scannedProduct = producto;
        state.ordenCompraDetalle.productos[index] = producto;
      }
    },
    [getScannedProductOrdenCompra.rejected]: (state, { payload }) => {
      state.isFetchingScannedProduct = false;
      state.isSuccessScannedProduct = false;
      state.isErrorScannedProduct = true;
      state.message = payload.message;
      state.error = payload.error;
    },
    [receiveOC.pending]: (state) => {
      state.isFetchingRecieveOC = true;
      state.isSuccessRecieveOC = false;
      state.isErrorRecieveOC = false;
    },
    [receiveOC.fulfilled]: (state, { payload }) => {
      state.isFetchingRecieveOC = false;
      state.isSuccessRecieveOC = true;
      state.isErrorRecieveOC = false;
      state.message = payload.message;
      state.ordenCompraDetalle.oc = payload.data.oc;
      state.ordenCompraDetalle.productos = payload.data.productos;
      state.ordenCompraDetalle.screenMode = "view";
    },
    [receiveOC.rejected]: (state, { payload }) => {
      state.isFetchingRecieveOC = false;
      state.isSuccessRecieveOC = false;
      state.isErrorRecieveOC = true;
      state.message = payload.message;
      state.error = payload.error;
    },
    [cancelOC.pending]: (state) => {
      state.isFetchingCancelOC = true;
      state.isSuccessCancelOC = false;
      state.isErrorCancelOC = false;
    },
    [cancelOC.fulfilled]: (state, { payload }) => {
      state.isFetchingCancelOC = false;
      state.isSuccessCancelOC = true;
      state.isErrorCancelOC = false;
      state.message = payload.message;
      state.ordenCompraDetalle.oc.status_id = payload.data.status.id;
      state.ordenCompraDetalle.oc.status = payload.data.status.name;
      state.ordenCompraDetalle.screenMode = "view";
    },
    [cancelOC.rejected]: (state, { payload }) => {
      state.isFetchingCancelOC = false;
      state.isSuccessCancelOC = false;
      state.isErrorCancelOC = true;
      state.message = payload.message;
      state.error = payload?.error ?? {
        code: "",
        message: "",
      };
    },
  },
});

export const {
  clearStateOrdenesCompra,
  clearStateOrdenCompraDetalle,
  clearStateError,
  changeScreenMode,
  closeProductDialog,
  changeCantidadRecibida,
  clearStateRecieveOC,
  clearStateScannedProduct,
  clearStateCancelOC,
  reset,
} = ordenesCompraSlice.actions;
export default ordenesCompraSlice.reducer;
