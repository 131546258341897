import React from "react";
import { Navigate } from "react-router-dom";
// import OCReception from "./pages/OCReception";

// const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const UserAdmin = React.lazy(() => import("./pages/UserAdmin"));
const Portales = React.lazy(() => import("./pages/MisPortales"));
const InvoiceTransaction = React.lazy(() =>
  import("./pages/InvoiceTransaction")
);
const SACTransaction = React.lazy(() => import("./pages/SACTransaction"));
const TransactionHistory = React.lazy(() =>
  import("./pages/TransactionHistory")
);
const Rutas = React.lazy(() => import("./pages/ALMRoutes"));
const RouteInvoicesSelection = React.lazy(() =>
  import("./pages/RouteInvoicesSelection")
);
// const RouteDetail = React.lazy(() => import("./pages/RouteDetail"));
const InvoiceDetail = React.lazy(() => import("./pages/InvoiceDetail"));
const InvoiceSearch = React.lazy(() => import("./pages/InvoiceSearch"));
const InvoicesToSupply = React.lazy(() => import("./pages/InvoicesToSupply"));
const Collection = React.lazy(() => import("./pages/Collection"));
const Page404 = React.lazy(() => import("./pages/Page404"));
const Unauthorized = React.lazy(() => import("./pages/Unauthorized"));
const PasswordReset = React.lazy(() => import("./pages/PasswordReset"));
const NoMovementInvoices = React.lazy(() =>
  import("./pages/NoMovementInvoices")
);
const LeoYCheco = React.lazy(() => import("./pages/LeoYCheco"));

const MailsCRUD = React.lazy(() => import("./pages/MailsCRUD"));
const TrucksCRUD = React.lazy(() => import("./pages/TrucksCRUD"));

const RouteDetailV2 = React.lazy(() => import("./pages/RouteDetailV2"));
const RouteLoad = React.lazy(() => import("./pages/RouteLoad"));

const BarcodeProductMaching = React.lazy(() =>
  import("./pages/BarcodeProductMaching")
);

const OCDetail = React.lazy(() => import("./pages/OCDetail"));
const OrdenesCompra = React.lazy(() => import("./pages/OrdenesCompra"));

const InvoiceArchiving = React.lazy(() => import("./pages/InvoiceArchiving"));

const NotFound = () => {
  return <Navigate to="/login" replace />;
};

export const public_routes = [
  // { path: "dashboard", exact: true, name: "Dashboard", element: Dashboard },
  {
    path: "no_movimiento",
    exact: true,
    name: "Facturas no movimiento",
    element: NoMovementInvoices,
  },
  { path: "portales", exact: true, name: "Portals", element: Portales },
  {
    path: "detalle_factura/:id",
    exact: true,
    name: "Invoice Detail",
    element: InvoiceDetail,
  },
  {
    path: "transaccion_por_categoria/detalle_factura/:id",
    exact: true,
    name: "Invoice Detail Category",
    element: InvoiceDetail,
  },
  {
    path: "transaccion_express/detalle_factura/:id",
    exact: true,
    name: "Invoice Detail Express Transaction",
    element: InvoiceDetail,
  },
  {
    path: "cobranza/detalle_factura/:id",
    exact: true,
    name: "Invoice Detail Collection",
    element: InvoiceDetail,
  },
  {
    path: "buscar_factura/detalle_factura/:id",
    exact: true,
    name: "Invoice Detail Searched",
    element: InvoiceDetail,
  },
  {
    path: "buscar_factura/detalle_factura",
    element: <Navigate to="/buscar_factura" replace />,
  },
  {
    path: "rutas/detalle_ruta/:ruta/detalle_factura/:id",
    exact: true,
    name: "Invoice Detail Route",
    element: InvoiceDetail,
  },
  {
    path: "facturas_por_surtir/detalle_factura/:id",
    exact: true,
    name: "Invoice Detail Supply Invoices",
    element: InvoiceDetail,
  },
  {
    path: "historial_transacciones/detalle_factura/:id",
    exact: true,
    name: "Transaction History Invoice Detail",
    element: InvoiceDetail,
  },
  {
    path: "transaccion_express",
    exact: true,
    name: "Invoice Transaction",
    element: InvoiceTransaction,
  },
  {
    path: "facturas_por_surtir",
    exact: true,
    name: "Invoices To Supply",
    element: InvoicesToSupply,
  },
  {
    path: "buscar_factura",
    exact: true,
    name: "Invoice Search",
    element: InvoiceSearch,
  },
  {
    path: "buscar_factura/:id",
    exact: true,
    name: "No movement report",
    element: InvoiceSearch,
  },
  {
    path: "buscar_factura/:user_id/detalle_factura/:id",
    exact: true,
    name: "Invoice Detail No Mov",
    element: InvoiceDetail,
  },
  {
    path: "transaccion_por_categoria",
    exact: true,
    name: "Invoice Category Transaction",
    element: SACTransaction,
  },
  {
    path: "historial_transacciones",
    exact: true,
    name: "Transaction History",
    element: TransactionHistory,
  },
  { path: "rutas", exact: true, name: "Routes", element: Rutas },
  {
    path: "rutas/cargar",
    exact: true,
    name: "Route Invoices Selection",
    element: RouteInvoicesSelection,
  },
  // {
  //   path: "rutas/detalle_ruta/:id",
  //   exact: true,
  //   name: "Route Detail",
  //   element: RouteDetail,
  // },
  {
    path: "rutas/detalle_ruta_v2/:id",
    exact: true,
    name: "Route Detail V2",
    element: RouteDetailV2,
  },

  { path: "cobranza", exact: true, name: "Collection", element: Collection },
  {
    path: "archivar_facturas",
    exact: true,
    name: "Invoice Archiving",
    element: InvoiceArchiving,
  },
  // ...(process.env.REACT_APP_COMPANY === "LUBTRAC"
  {
    path: "leo_y_checo",
    exact: true,
    name: "Leo y Checo",
    element: LeoYCheco,
  },
  //   : {}),
  {
    path: "unauthorized",
    exact: true,
    name: "Unauthorized",
    element: Unauthorized,
  },
  {
    path: "rutas/correos",
    exact: true,
    name: "Mails CRUD",
    element: MailsCRUD,
  },
  {
    path: "rutas/camiones",
    exact: true,
    name: "Trucks CRUD",
    element: TrucksCRUD,
  },
  { path: "*", exact: false, name: "Page 404", element: Page404 },
  {
    path: "asignar_codigos",
    exact: true,
    name: "Barcode Product Maching",
    element: BarcodeProductMaching,
  },
  {
    path: "ordenes_compra",
    exact: true,
    name: "Ordenes de compra",
    element: OrdenesCompra,
  },
  {
    path: "ordenes_compra/:num_pedido",
    exact: true,
    name: "Recepción OC",
    element: OCDetail,
  },
];

export const test_routes = [
  /**
   * Control de inventarios
   *
   */
  // {
  //   path: "rutas/:id/picking",
  //   exact: true,
  //   name: "Route Picking",
  //   element: RoutePicking,
  // },
  {
    path: "rutas/:id/carga",
    exact: true,
    name: "Route Picking",
    element: RouteLoad,
  },
  /**
   *
   *
   */
];

export const sa_routes = [
  { path: "usuarios", exact: true, name: "Users", element: UserAdmin },
  // {
  //   path: "rutas/correos",
  //   exact: true,
  //   name: "Mails CRUD",
  //   element: MailsCRUD,
  // },
  // {
  //   path: "rutas/camiones",
  //   exact: true,
  //   name: "Trucks CRUD",
  //   element: TrucksCRUD,
  // },
];

export const account_routes = [
  {
    path: "password/reset",
    exact: true,
    name: "Password Reset",
    element: PasswordReset,
  },
  { path: "*", exact: false, name: "Page 404", element: NotFound },
];
